<template>
  <VDialog
    v-model="visibility"
    :persistent="changed"
    @click:outside="showConfirmDialog"
    max-width="600px"
    content-class="cross__dialog"
    scrollable

  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
      <div class="cross__inner">
        <VBtn fab small color="white" elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
      <VRow no-gutters justify="space-between" class="mx-4" align="center">
        <span
         class="headline"
         :class="{ 'text-body-1': $vuetify.breakpoint.xs }"
         >
         {{ $t('cell.update_cell') }}
         </span>
        </VRow>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>

          <div class="" v-else>
            <VRow>
              <VCol cols="12">
                <VTextField
                  outlined dense
                  :label="$t('form.name')"
                  v-model="inputCell.name"
                  required
                  :error-messages="nameErrors"
                  hide-details="auto"
                ></VTextField>
              </VCol>
            </VRow>
            <VRow>

              <VCol cols="12" md="6">
                <v-autocomplete
                  :label="$t('form.sector')"
                  :items="sectors"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :no-data-text="$t('table.noData')"
                  outlined
                  dense
                  v-model="inputCell.uuid_sector"
                  :error-messages="uuidErrors"
                  hide-details="auto"
                  @change="onChangeSector"
                  small-chips
                />
              </VCol>
              <VCol cols="12" md="6">
                <v-autocomplete
                  class="pa-0"
                  dense
                  :label="$t('cell.parent_cell')"
                  v-model="inputCell.uuid_parent"
                  outlined
                  hide-details="auto"
                  :items="cells"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  color="deep-purple"
                  :loading="isLoadingParent"
                  clearable
                />
              </VCol>
              <VCol cols="12">
                <VTextField
                  outlined dense
                  :label="$t('table.ns_code')"
                  v-model="inputCell.ns_code"
                  required
                  :error-messages="nsCodeErrors"
                  hide-details="auto"
                ></VTextField>
              </VCol>
              <VCol cols="12" class="py-0 my-0">
                <VTextarea
                  outlined dense
                   :label="$t('form.description')"
                  required
                  v-model="inputCell.description"
                  no-resize
                  counter
                  maxlength="255"
                  rows="5"

                ></VTextarea>
              </VCol>
            </VRow>
            <div v-if="items.length">
              <h3 :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{$t('cell.available_items')}}</h3>
              <VCard
                elevation="2"
                class="pa-2 mb-1"
                v-for="item in items"
                :key="item.uuid"
              >
                <div>
                {{$t('form.name')}}: <b>{{ item.product_details.name | noData }}</b><br>
                <p v-html="item.product_details.description || '--'"></p>
                <br>
                </div>
                <div v-if="item.options_details && item.options_details.length">
                  <div>{{$t('products.options')}}:</div>
                  <ul>
                    <li v-for="option in item.options_details" :key="option.uuid">
                      {{ `${option.name}: ${option.amount} ${$t('table.piece')}`}}
                    </li>
                  </ul>
                </div>
                <br>
                <small>{{ item.time_created | noData }}</small>
              </VCard>
            </div>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol col="6">
            <VBtn
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              dark
              block
              class="w-100 br-10 text-transform-none "
              @click="visibility = false"
            >
              {{ $t('btn.cancel') }}
            </VBtn>
          </VCol>
          <VCol col="6">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              block
              :disabled="!changed"
              @click="onUpdate"
            >
                 {{ $t('btn.update') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import nsCodeMask from '@/validators/nsCodeMask';
import language from '@/mixins/language';
import notifications from '../../mixins/notifications';
import depotService from '../../services/request/depot/depotService';
import EventBus from '../../events/EventBus';
import { uniqueByUUID } from '../../mixins/helpers';
import user from '../../mixins/user';

const defaultUUID = '00000000-0000-0000-0000-000000000000';
export default {
  name: 'UpdateCellDialog',
  mixins: [language, notifications, validationMixin, user],
  validations: {
    inputCell: {
      name: { required },
      uuid_sector: { required },
      ns_code: { required, nsCodeMask },
    },
  },
  components: {
    ConfirmLeftDialog,
  },
  data: () => ({
    isLoadingParent: false,
    loading: false,
    visibleConfirm: false,
    changed: false,
    // items: [],
    inputCell: {},
    cells: [],
    // sectors: [],
  }),
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    cell: {
      require: true,
    },
    sectors: {
      require: true,
    },
    items: {
      require: true,
    },
  },
  watch: {
    inputCell: {
      deep: true,
      handler() {
        if(this.inputCell.uuid_parent === null){
          this.inputCell.uuid_parent = defaultUUID
        }
        console.log(this.inputCell)
        this.changed = !_.isEqual(this.cell, this.inputCell);
      },
    },
    role: 'updateRoleInfo',
  },

  methods: {
    onChangeSector(uuidSector){
      if(this.inputCell.uuid_parent){
        this.inputCell.uuid_parent = defaultUUID
      }
      this.getCellsList(uuidSector)

    },
    async getCellsList(uuidSector) {
      try {
        this.isLoadingParent = true;
        const params = {
          order: 'DESC',
          orderby: 'time_created',
        };
        if (this.permissions.owner) {
          params.sector_details__uuid_deport = this.inputCell.sector_details.uuid_deport;
        }
        if(uuidSector){
          params.uuid_sector = uuidSector
        }
        const newItems = await depotService.getCellsList(params);
        this.cells = newItems.filter((e) => e.uuid !== this.inputCell.uuid);
        this.cells = uniqueByUUID(this.cells);
      } catch (e) {
        this.setErrorNotification(e);
      }finally {
        this.isLoadingParent = false;
      }
    },

    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          if(this.inputCell.uuid_parent && this.inputCell.uuid_parent == defaultUUID){
           this.inputCell.uuid_parent = this.cell.uuid_parent
          }
          formData.append('uuid', this.inputCell.uuid);
          const changedData = this.$getChangedData(this.inputCell, this.cell);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          if (changedData.length) {
            await depotService.updateCell(formData);
            this.setSuccessNotification(this.$t('cell.cell_updated'));
            EventBus.$emit('cell-updated');
          }
          this.visibility = false;
        } catch (e) {
          this.setErrorNotification(this.$t('cell.circular_dependency'));
          // this.setErrorNotification(e.error.description);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  mounted() {
    // // orderDialog('this.sectors', this.sectors);
    this.inputCell = { ...this.cell };
    this.parent_cell = this.cell.uuid_parent;
    this.getCellsList(this.inputCell.uuid_sector);
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.inputCell.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputCell.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.inputCell.uuid_sector.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputCell.uuid_sector.required
      && errors.push(this.$t('form.errors.SectorRequired'));
      return errors;
    },
    nsCodeErrors() {
      const errors = [];
      if (!this.$v.inputCell.ns_code.$dirty) {
        return errors;
      }
      if (!this.$v.inputCell.ns_code.nsCodeMask) {
        errors.push(this.$t('form.errors.NsCodeValidation'));
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputCell.ns_code.required
      && errors.push(this.$t('form.errors.NsCodeRequired'));
      return errors;
    },
  },
};
</script>

<style scoped>

</style>
